.card {
  box-shadow: $card-box-shadow;
  .card-header {
    padding: $card-header-padding;
  }






  // !important
  // These need to be made as variables mx-2.5 my-2.5 mt-2.5 mb-2.5 etc. for 2.5 = 0.75rem; or something similar -
  // they were made just for Corporate Design

  .card-header-mx-2{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .card-header-mt-2{
    margin-top: 0.75rem !important;
  }




  // Please read upper message! Don't push this on Live




  .card-body {
    padding: $card-body-padding;
  }

  &.card-plain {
    background-color: $card-plain-bg-color;
    box-shadow: $card-plain-box-shadow;
  }

  .card-footer {
    padding: $card-footer-padding;
    background-color: transparent;
  }
}

.author {
  display: $card-author-display;

  .name > span {
    line-height: $card-author-name-line-height;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: $card-author-name-color;
  }

  .stats {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

@import 'cards/card-background';
@import 'cards/card-product';
