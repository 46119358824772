.form-check:not(.form-switch) .form-check-input {
  &[type="checkbox"],
  &[type="radio"] {
    border: 1px solid darken($gray-200, 10%);
    margin-top: $form-text-margin-top;
    position: relative;

    &:checked {
      border: 0;
    }
  }

  &.form-check-input-info:checked[type="radio"] {
    background-image: none;
    background-color: $info;
  }

  &[type="checkbox"] {
    &:after {
      transition: opacity $form-check-transition-time ease-out;
      font-family: "FontAwesome";
      content: "\f00c";
      width: 100%;
      height: 100%;
      color: $form-check-input-checked-color;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      opacity: 0;
    }

    &:checked:after {
      opacity: 1;
    }
  }

  &[type="radio"] {
    transition: border 0s;

    &:after {
      transition: opacity $form-check-transition-time ease-in-out;
      content: "";
      position: absolute;
      width: $form-check-radio-after-width;
      height: $form-check-radio-after-width;
      border-radius: 50%;
      background-color: $white;
      opacity: 0;
    }

    &:checked {
      padding: 6px;
    }

    &:checked:after {
      opacity: 1;
    }
  }
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label{
  cursor: no-drop;
}

.form-check-label,
.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.form-check-label{
  font-size:$font-size-sm;
  font-weight: $font-weight-normal;
}

.form-check-input{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


// Ecommerce Astro

.card-product {
  .form-check:not(.form-switch) .form-check-input {
    &[type="radio"] {
      display: none;

      + label {
        transition: border 0s;
        width: 70px;
        height: 70px;
        border: 1px solid #cbd3da;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .5rem;
        user-select: none;
      
        &.label-lg {
          width: 100% !important;
          height: 100% !important;
          padding: .75rem;
          display: block;
        }
      }

      &:checked + label {
        border-color: $primary;
      }
    }
  }
  .form-check {
    position: relative;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-check-input {
      + label {
        margin-left: 0;
        margin-top: 0 !important;
        cursor: pointer;
      }     

      &[disabled] {
        + label {
          cursor: not-allowed;
          pointer-events: all;
        }
        &:hover + label {
          background-color: $white;
        }
      }

      &:hover + label {
        background-color: $light;
      }
    }
  }

}

.product-quickview {
  .form-check:not(.form-switch) .form-check-input {
    &[type="radio"] {
      transition: border 0s;
      width: 240px;
      height: 120px;

      &:after {
        transition: opacity $form-check-transition-time ease-in-out;
        width: 66px;
        height: 66px;
        background-color: $white;
        opacity: 0;
        border-radius: 4px;
        top: 2px;
        left: 2px;
      }
  
      &:checked:after {
        opacity: 1;
      }
    }
  }
}